import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ThemeMode } from 'config';
import Chip from 'ui-component/extended/Chip';
import useAuth from 'hooks/useAuth';
import { useAcceptInvitation, useDismissInvitation } from 'api/organisers';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import GetAuthorityLabel from 'utils/getAuthorityLabel';
import { useState } from 'react';

const ListItemWrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        cursor: 'pointer',
        '&:hover': {
          bgcolor:
            theme.palette.mode === ThemeMode.DARK
              ? 'dark.main'
              : 'primary.light',
        },
      }}
    >
      {children}
    </Box>
  );
};

ListItemWrapper.propTypes = {
  children: PropTypes.node,
};

const NotificationList = () => {
  const { user } = useAuth();
  const [invitations, setInvitations] = useState(
    user?.pendingInvitations ?? [],
  );
  const { mutateAsync: acceptInvite } = useAcceptInvitation();
  const { mutateAsync: dismissInvite } = useDismissInvitation();

  const handleAccept = async (id) => {
    await acceptInvite(id);
    setInvitations(user.pendingInvitations.filter((inv) => inv.id !== id));
  };
  const handleDeny = async (id) => {
    await dismissInvite(id);
    setInvitations(user.pendingInvitations.filter((inv) => inv.id !== id));
  };

  return (
    <List sx={{ width: '100%', maxWidth: { xs: 300, md: 330 }, py: 0 }}>
      {invitations.map((inv) => (
        <ListItemWrapper key={inv.id}>
          <Stack spacing={2}>
            <Typography variant="subtitle2">
              <IFormattedMessage
                id="pendingInvitation"
                values={{
                  fullname: `${inv.sender.name} ${inv.sender.surname}`,
                  authorityLabel: GetAuthorityLabel(
                    inv.authority.authorityGroup,
                  ),
                }}
              />
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip
                label={<IFormattedMessage id="actions.deny" />}
                chipcolor="error"
                variant="outlined"
                size="large"
                onClick={() => handleDeny(inv.id)}
              />
              <Chip
                label={<IFormattedMessage id="actions.accept" />}
                chipcolor="success"
                variant="outlined"
                size="large"
                onClick={() => handleAccept(inv.id)}
              />
            </Stack>
          </Stack>
        </ListItemWrapper>
      ))}
    </List>
  );
};

export default NotificationList;
