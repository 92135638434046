import SecurityIcon from '@mui/icons-material/Security';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';

const organisers = {
  id: 'organisers',
  admin: true,
  title: <IFormattedMessage id="menu.admin" />,
  icon: SecurityIcon,
  type: 'group',
  children: [
    {
      id: 'organisers',
      admin: true,
      title: <IFormattedMessage id="menu.organisers" />,
      type: 'item',
      icon: BusinessIcon,
      url: '/organisers',
    },
    {
      id: 'users',
      admin: true,
      title: <IFormattedMessage id="menu.users" />,
      type: 'item',
      icon: PeopleIcon,
      url: '/users',
    },
  ],
};

export default organisers;
