import orgLogo from 'assets/images/upload/kapital-logo.png';
const VITE_AWS_S3_ENDPOINT = import.meta.env.VITE_AWS_S3_ENDPOINT;
export const getLogoUrl = (path) =>
  path ? `${VITE_AWS_S3_ENDPOINT}/${path}` : orgLogo;

export const getBucketPath = (
  path,
  placeholderWidth = 1920,
  placeholderHeight = 600,
) =>
  path
    ? `${VITE_AWS_S3_ENDPOINT}/${path}`
    : `https://placehold.co/${placeholderWidth}x${placeholderHeight}`;

export default getLogoUrl;
