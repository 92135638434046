import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Tickets = Loadable(lazy(() => import('views/register/pages/tickets')));
const Checkout = Loadable(lazy(() => import('views/register/pages/checkout')));
const TicketManagement = Loadable(
  lazy(() => import('views/register/pages/ticket-management')),
);

const RegisterRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/events/register/:formId',
      element: <Tickets />,
    },
    {
      path: '/events/register/:formId/checkout',
      element: <Checkout />,
    },
    {
      path: '/events/register/:formId/ticket-management/:orderNo',
      element: (
        <AuthGuard redirToProfile>
          <TicketManagement />
        </AuthGuard>
      ),
    },
  ],
};

export default RegisterRoutes;
