import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavItem from './NavItem';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
import menuItem from 'menu-items';
import eventMenuItems from 'event-menu-items';
import { HORIZONTAL_MAX_ITEM, MenuOrientation } from 'config';
import { useGetMenuMaster } from 'api/menu';
import { useSelector } from 'react-redux';
import AuthorityRenderer from 'hooks/useAuthorityRenderer';
import { AuthorityGroup } from 'utils/authorityGroup';
import useAuth from 'hooks/useAuth';
import adminMenuItems from 'admin-menu-items';
import { Roles } from 'utils/roles';

const MenuList = () => {
  const isEventActive = useSelector((state) => state.event.isEvent);
  const { user } = useAuth();
  const activeMenu = isEventActive
    ? eventMenuItems
    : user?.role === Roles.Admin
      ? adminMenuItems
      : menuItem;

  const downMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { menuOrientation } = useConfig();
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downMD;
  const [selectedID, setSelectedID] = useState('');

  // last menu-item to show in horizontal menu bar
  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;

  let lastItemIndex = activeMenu.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < activeMenu.items.length) {
    lastItemId = activeMenu.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = activeMenu.items
      .slice(lastItem - 1, activeMenu.items.length)
      .map((item) => ({
        title: item.title,
        elements: item.children,
        icon: item.icon,
        ...(item.url && {
          url: item.url,
        }),
      }));
  }

  const navItems = activeMenu.items
    .slice(0, lastItemIndex + 1)
    .map((item, index) => {
      switch (item.type) {
        case 'group':
          if (item.url && item.id !== lastItemId) {
            return (
              <AuthorityRenderer
                key={item.id}
                accept={
                  item.roles?.length > 0
                    ? [AuthorityGroup.Owner, Roles.Admin, ...item.roles]
                    : null
                }
              >
                <List>
                  <NavItem
                    item={item}
                    level={1}
                    isParents
                    setSelectedID={() => setSelectedID('')}
                  />
                  {!isHorizontal && index !== 0 && <Divider sx={{ py: 0.5 }} />}
                </List>
              </AuthorityRenderer>
            );
          }
          return (
            <AuthorityRenderer
              key={item.id}
              accept={
                item.roles?.length > 0
                  ? [AuthorityGroup.Owner, Roles.Admin, ...item.roles]
                  : null
              }
            >
              <NavGroup
                setSelectedID={setSelectedID}
                selectedID={selectedID}
                item={item}
                lastItem={lastItem}
                remItems={remItems}
                lastItemId={lastItemId}
              />
            </AuthorityRenderer>
          );
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return !isHorizontal ? (
    <Box {...(drawerOpen && { sx: { mt: 1.5 } })}>{navItems}</Box>
  ) : (
    <>{navItems}</>
  );
};

export default memo(MenuList);
