// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// file constants
export const MAX_FILE_SIZE = 5 * 1000 * 1000;
export const LOGO_WIDTH = 500;
export const LOGO_HEIGHT = 500;
export const COVER_WIDTH = 1120;
export const COVER_HEIGHT = 500;
export const HEADER_WIDTH = 1200;
export const HEADER_HEIGHT = 300;
