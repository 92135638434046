import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  printerId: null,
};

const printSlice = createSlice({
  name: 'printer',
  initialState,
  reducers: {
    setPrinterId(state, action) {
      state.printerId = action.payload;
    },
  },
});

export default printSlice.reducer;

export const { setPrinterId } = printSlice.actions;
