import { Box, InputLabel, TextField } from '@mui/material';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import ErrorHelper from './ErrorHelper';
import { get } from 'lodash-es';
import useUserAuthority from 'hooks/useUserAuthority';
import { AuthorityGroup } from 'utils/authorityGroup';
import { Roles } from 'utils/roles';

export default function ITextField({
  field,
  parent,
  errorId = null,
  value = null,
  label = null,
  hideLabel = false,
  disabled = false,
  placeholder = null,
  formik = null,
  preventDefault = false,
  afterOnChange = (e) => { },
  InputProps = {},
  sx = {},
  props = {},
  noTL = false,
  title = false
}) {
  const {
    values,
    handleChange,
    touched,
    errors,
    handleBlur,
    isSubmitting,
    initialStatus,
  } = formik ??
  useContext(FormikContext) ?? {
      values: {},
      touched: {},
      errors: {},
      initialStatus: {},
      handleChange: () => { },
      handleBlur: () => { },
      isSubmitting: false,
    };

  const { allow } = initialStatus ?? {};
  const allowed = allow
    ? [AuthorityGroup.Owner, Roles.Admin, ...allow].includes(useUserAuthority())
    : true;
  const inputDisabled = disabled || !allowed || isSubmitting;

  return (
    <>
      {Boolean(title) && <Box>{label}</Box>}
      <TextField
        id={field}
        name={field}
        label={
          Boolean(!title) ? (
            hideLabel ? null : noTL ? (
              <>{label ?? `${parent}.${field}`}</>
            ) : (
              <IFormattedMessage id={label ?? `${parent}.${field}`} />
            )
          ) : null
        }
        fullWidth
        placeholder={placeholder}
        disabled={inputDisabled}
        value={value ?? get(values, field) ?? ''}
        onChange={(e) => {
          if (!preventDefault) handleChange(e);
          afterOnChange(e);
        }}
        onBlur={handleBlur}
        InputProps={InputProps}
        error={get(touched, field) && Boolean(get(errors, field))}
        sx={sx}
        {...props}
      />
      <ErrorHelper
        field={errorId ? `${field}-${errorId}` : field}
        ignoreTouched={Boolean(errorId)}
        formik={formik}
      />
    </>
  );
}
