import { createIntl, createIntlCache } from 'react-intl';
import { flatten } from 'flat';
import { merge } from 'lodash-es';

// English
import EnglishAction from './locales/en/action.json';
import EnglishAdmin from './locales/en/admin.json';
import EnglishContract from './locales/en/contract.json';
import EnglishControlPoint from './locales/en/controlPoint.json';
import EnglishCoupon from './locales/en/coupon.json';
import English from './locales/en/default.json';
import EnglishEvent from './locales/en/event.json';
import EnglishFAQ from './locales/en/faq.json';
import EnglishInput from './locales/en/input.json';
import EnglishInvitation from './locales/en/invitation.json';
import EnglishLicenses from './locales/en/licenses.json';
import EnglishOrder from './locales/en/order.json';
import EnglishOrganiser from './locales/en/organiser.json';
import EnglishPackage from './locales/en/package_.json';
import EnglishParticipant from './locales/en/participant.json';
import EnglishPayment from './locales/en/payment.json';
import EnglishPaymentMethod from './locales/en/paymentMethod.json';
import EnglishProgram from './locales/en/program.json';
import EnglishPromotion from './locales/en/promotion.json';
import EnglishQuestion from './locales/en/question.json';
import EnglishSpeaker from './locales/en/speaker.json';
import EnglishSponsor from './locales/en/sponsor.json';
import EnglishTag from './locales/en/tag.json';
import EnglishTicket from './locales/en/ticket.json';
import EnglishValidation from './locales/en/validation.json';
import EnglishWidgets from './locales/en/widgets.json';
import EnglishNotification from './locales/en/notification.json';
import EnglishTopic from './locales/en/topic.json';

// Turkish
import TurkishAction from './locales/tr/action.json';
import TurkishAdmin from './locales/tr/admin.json';
import TurkishContract from './locales/tr/contract.json';
import TurkishControlPoint from './locales/tr/controlPoint.json';
import TurkishCoupon from './locales/tr/coupon.json';
import Turkish from './locales/tr/default.json';
import TurkishEvent from './locales/tr/event.json';
import TurkishFAQ from './locales/tr/faq.json';
import TurkishInput from './locales/tr/input.json';
import TurkishInvitation from './locales/tr/invitation.json';
import TurkishLicenses from './locales/tr/licenses.json';
import TurkishOrder from './locales/tr/order.json';
import TurkishOrganiser from './locales/tr/organiser.json';
import TurkishPackage from './locales/tr/package_.json';
import TurkishParticipant from './locales/tr/participant.json';
import TurkishPayment from './locales/tr/payment.json';
import TurkishPaymentMethod from './locales/tr/paymentMethod.json';
import TurkishProgram from './locales/tr/program.json';
import TurkishPromotion from './locales/tr/promotion.json';
import TurkishQuestion from './locales/tr/question.json';
import TurkishSpeaker from './locales/tr/speaker.json';
import TurkishSponsor from './locales/tr/sponsor.json';
import TurkishTag from './locales/tr/tag.json';
import TurkishTicket from './locales/tr/ticket.json';
import TurkishValidation from './locales/tr/validation.json';
import TurkishWidgets from './locales/tr/widgets.json';
import TurkisNotification from './locales/tr/notification.json';
import TurkishTopic from './locales/tr/topic.json';

import { getLocaleConfig } from './date/getLocale';

export const messages = {
  en: merge(
    EnglishAction,
    EnglishAdmin,
    EnglishContract,
    EnglishControlPoint,
    EnglishCoupon,
    English,
    EnglishEvent,
    EnglishFAQ,
    EnglishInput,
    EnglishInvitation,
    EnglishLicenses,
    EnglishOrder,
    EnglishOrganiser,
    EnglishPackage,
    EnglishParticipant,
    EnglishPayment,
    EnglishPaymentMethod,
    EnglishProgram,
    EnglishPromotion,
    EnglishQuestion,
    EnglishSpeaker,
    EnglishSponsor,
    EnglishTag,
    EnglishTicket,
    EnglishValidation,
    EnglishWidgets,
    EnglishNotification,
    EnglishTopic,
  ),
  tr: merge(
    TurkishAction,
    TurkishAdmin,
    TurkishContract,
    TurkishControlPoint,
    TurkishCoupon,
    Turkish,
    TurkishEvent,
    TurkishFAQ,
    TurkishInput,
    TurkishInvitation,
    TurkishLicenses,
    TurkishOrder,
    TurkishOrganiser,
    TurkishPackage,
    TurkishParticipant,
    TurkishPayment,
    TurkishPaymentMethod,
    TurkishProgram,
    TurkishPromotion,
    TurkishQuestion,
    TurkishSpeaker,
    TurkishSponsor,
    TurkishTag,
    TurkishTicket,
    TurkishValidation,
    TurkishWidgets,
    TurkisNotification,
    TurkishTopic,
  ),
};

const getLocale = () =>
  JSON.parse(localStorage.getItem('kapital-event-config'))?.i18n ||
  getLocaleConfig();

const cache = createIntlCache();

const intl = (locale = getLocale()) =>
  createIntl(
    {
      locale: locale,
      messages: flatten(messages[locale]),
    },
    cache,
  );

export const getErrorTl = (intl, parent, message) =>
  Boolean(intl.messages[`${parent}.${message}`])
    ? intl.formatMessage({
        id: `${parent}.${message}`,
      })
    : message;

export default intl;
