import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeOrganiser: null,
};

export const organiserSlice = createSlice({
  name: 'organiser',
  initialState,
  reducers: {
    setActiveOrganiser: (state, action) => {
      state.activeOrganiser = action.payload;
    },
  },
});

export const { setActiveOrganiser } = organiserSlice.actions;
export default organiserSlice.reducer;
