import HandymanIcon from '@mui/icons-material/Handyman';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { AuthorityGroup } from 'utils/authorityGroup';
import BadgeIcon from '@mui/icons-material/Badge';
import Print from '@mui/icons-material/Print';
import { Loyalty, NotificationAdd } from '@mui/icons-material';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';

const utilites = {
  id: 'utilities',
  roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
  title: <IFormattedMessage id="menu.utilities" />,
  icon: HandymanIcon,
  type: 'group',
  children: [
    {
      id: 'control-points',
      roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
      title: <IFormattedMessage id="menu.controlPoints" />,
      type: 'item',
      icon: QrCodeScannerIcon,
      url: '/control-points',
      event: true,
    },
    {
      id: 'test-control-points',
      roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
      title: <IFormattedMessage id="menu.testControlPoints" />,
      type: 'item',
      icon: CropFreeIcon,
      url: '/test-control-points',
      event: true,
    },
    {
      id: 'badge-designs',
      roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
      title: <IFormattedMessage id="menu.badgeDesigns" />,
      type: 'item',
      icon: BadgeIcon,
      url: '/badge-designs',
      event: true,
    },
    {
      id: 'promotion',
      roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
      title: <IFormattedMessage id="menu.promotion" />,
      type: 'item',
      icon: Loyalty,
      url: '/promotion',
      event: true,
    },
    {
      id: 'notification',
      roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
      title: <IFormattedMessage id="menu.notification" />,
      type: 'item',
      icon: NotificationAdd,
      url: '/notification',
      event: true,
    },
  ],
};

export default utilites;
