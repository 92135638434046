import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from './Header';
import Sidebar from './Sidebar';
import EventSidebar from './Sidebar/EventSideBar';
import HorizontalBar from './HorizontalBar';
import MainContentStyled from './MainContentStyled';
import Loader from 'ui-component/Loader';
import useConfig from 'hooks/useConfig';
import { handlerDrawerOpen, useGetMenuMaster } from 'api/menu';
import { MenuOrientation } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { eventActiveFunc } from 'store/slices/event/event';
import { closeSnackbar } from 'store/slices/snackbar';
import snackbar from 'utils/snackbar';
import { useDeepEffect } from 'utils/hooks/useEffectHooks';

const MainLayout = () => {
  const theme = useTheme();
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const { borderRadius, container, miniDrawer, menuOrientation } = useConfig();
  const { menuMaster, menuMasterLoading } = useGetMenuMaster();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;

  useEffect(() => {
    handlerDrawerOpen(!miniDrawer);
  }, [miniDrawer]);

  useEffect(() => {
    downMD && handlerDrawerOpen(false);
  }, [downMD]);

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downMD;
  const location = useLocation();
  useDeepEffect(() => {
    // Error snackbar
    dispatch(closeSnackbar());
    // Unsaved changes snackbar
    snackbar.close();
  }, [location?.pathname]);
  const pathParts = location.pathname.split('/');

  // sidebar değişim işlemleri burada yapılıyor.
  const isEventActive = useSelector((state) => state.event.isEvent);
  const { id } = useParams();
  const params = useParams();
  useEffect(() => {
    dispatch(eventActiveFunc(id && pathParts.includes('event')));
  }, [params, dispatch, id, pathParts]);

  // horizontal menu-list bar : drawer
  const menu = useMemo(
    () => (isHorizontal ? <HorizontalBar /> : <Sidebar />),
    [isHorizontal],
  );
  const menuEvent = useMemo(() => <EventSidebar />, []);

  if (menuMasterLoading) return <Loader />;

  return (
    <Box sx={{ display: 'flex' }}>
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ bgcolor: 'background.default' }}
      >
        <Toolbar sx={{ p: isHorizontal ? 1.25 : 2 }}>
          <Header />
        </Toolbar>
      </AppBar>

      {/* menu / drawer */}

      {isEventActive ? menuEvent : menu}

      {/* main content */}
      <MainContentStyled
        {...{ borderRadius, menuOrientation, open: drawerOpen, theme }}
      >
        <Container
          maxWidth={container ? 'lg' : false}
          {...(!container && { sx: { px: { xs: 0 } } })}
        >
          <Outlet />
        </Container>
      </MainContentStyled>
    </Box>
  );
};

export default MainLayout;
