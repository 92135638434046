import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CardMedia from '@mui/material/CardMedia';
import OrganiserAddModal from 'ui-component/organiser/OrganiserAddModal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useGetMenuMaster } from 'api/menu';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import { useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import { IconSearch, IconCaretRight, IconPlus } from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';
import { useGetOrganisers, useCreateOrganiser } from 'api/organisers';
import { setActiveOrganiser } from 'store/slices/organiser/organiser';
import getLogoUrl from 'utils/getLogoUrl';
import useAuth from 'hooks/useAuth';
import { Roles } from 'utils/roles';
import AuthorityRenderer from 'hooks/useAuthorityRenderer';

const OrganisersSideBar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();
  const [selectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;
  const [searchTerm, setSearchTerm] = useState('');
  const { data, dataLoading } = useGetOrganisers();

  const { id } = useParams();

  const activeOrganiser = useSelector(
    (state) => state.organiser.activeOrganiser,
  );
  const activeOrganiserId = activeOrganiser?.id ?? id;

  useEffect(() => {
    if (!dataLoading && data && !data.getIds().includes(activeOrganiserId)) {
      dispatch(setActiveOrganiser(data[0]));
    }
  }, [dataLoading, data, activeOrganiserId]);

  // searchTerm'e göre filtrelenen items dizisi
  const filteredItems =
    data && Array.isArray(data)
      ? data.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : [];

  const handleListItemClick = async (data) => {
    dispatch(setActiveOrganiser(data));
    setSearchTerm('');
    navigate(`/organiser/${data.id}`, { replace: true });
  };

  const goToOrganiser = () => {
    if (activeOrganiser)
      navigate(`/organiser/${activeOrganiser.id}`, { replace: true });
  };

  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open, dataLoading]);

  // Modal Alanı
  const { mutateAsync: createOrganiser } = useCreateOrganiser();
  const [isAdding, setIsAdding] = useState(false);

  const handleAddOrganiser = () => {
    setIsAdding(true);
  };

  const handleOrganiserSave = async ({ name }) => {
    await createOrganiser({ name });
    setIsAdding(false);
  };

  const { user } = useAuth();
  if (user?.role === Roles.Admin) return <></>;

  return (
    <>
      {dataLoading ? (
        <>
          <CircularProgress aria-label="progress" />
        </>
      ) : (
        <>
          <Box
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="primary"
            aria-label="user-account"
            sx={{
              justifyContent: drawerOpen ? 'center' : 'center',
            }}
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <>
              <Box onClick={goToOrganiser}>
                <CardMedia
                  component="img"
                  image={getLogoUrl(activeOrganiser?.logo)}
                  title="image"
                  sx={{
                    ...theme.typography.CardMedia,
                    width: 40,
                    height: 'auto',
                    cursor: 'pointer',
                  }}
                  ref={anchorRef}
                />
              </Box>

              {drawerOpen && (
                <>
                  <Box
                    minWidth={'10em'}
                    style={{ marginLeft: '1em', flex: 1 }}
                    onClick={goToOrganiser}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        lineClamp: 2,
                        whiteSpace: 'normal',
                        fontSize: '0.85rem',
                      }}
                    >
                      {activeOrganiser?.name}
                    </Typography>
                  </Box>
                  <Box onClick={handleToggle}>
                    <IconCaretRight stroke={1.5} size="28px" />
                  </Box>
                </>
              )}
            </>
          </Box>
          <Popper
            sx={{ zIndex: 99999 }}
            placement="right-start"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 20],
                },
              },
            ]}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClose}>
                <Transitions in={open} {...TransitionProps}>
                  <Paper>
                    {open && (
                      <MainCard
                        border={false}
                        elevation={16}
                        content={false}
                        boxShadow
                        shadow={theme.shadows[16]}
                      >
                        <Box sx={{ p: 2, pb: 0 }}>
                          <OutlinedInput
                            sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                            id="input-search-profile"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={intl.formatMessage({
                              id: 'actions.search',
                            })}
                            startAdornment={
                              <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="16px" />
                              </InputAdornment>
                            }
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                          />
                          <Divider />
                        </Box>
                        <PerfectScrollbar
                          style={{
                            height: '100%',
                            maxHeight: 'calc(100vh - 250px)',
                            overflowX: 'hidden',
                          }}
                        >
                          <Box sx={{ p: 2, pt: 0 }}>
                            <Divider />
                            <List
                              component="nav"
                              sx={{
                                width: '100%',
                                maxWidth: 350,
                                minWidth: 300,
                                borderRadius: `${borderRadius}px`,
                                '& .MuiListItemButton-root': { mt: 0.5 },
                              }}
                            >
                              {filteredItems.length === 0 ? (
                                <>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    textAlign={'center'}
                                    pt={2}
                                  >
                                    <IFormattedMessage id="event-search-not-found" />
                                  </Typography>
                                </>
                              ) : (
                                filteredItems.map((item, key) => (
                                  <ListItemButton
                                    key={key}
                                    sx={{
                                      borderRadius: `${borderRadius}px`,
                                      display: 'flex',
                                      gap: '10px',
                                      alignItems: 'center',
                                    }}
                                    selected={selectedIndex === 0}
                                    onClick={() => handleListItemClick(item)}
                                  >
                                    <Box>
                                      <CardMedia
                                        component="img"
                                        image={getLogoUrl(item.logo)}
                                        title={item.name}
                                        sx={{
                                          ...theme.typography.CardMedia,
                                          width: 30,
                                          height: 'auto',
                                          cursor: 'pointer',
                                        }}
                                        alt={item.name}
                                      />
                                    </Box>
                                    <ListItemText
                                      key={key}
                                      primary={
                                        <>
                                          <Typography
                                            component="h2"
                                            fontWeight={600}
                                          >
                                            {item.name}{' '}
                                          </Typography>
                                        </>
                                      }
                                    />
                                  </ListItemButton>
                                ))
                              )}
                            </List>
                          </Box>
                        </PerfectScrollbar>
                      </MainCard>
                    )}
                  </Paper>
                </Transitions>
              </ClickAwayListener>
            )}
          </Popper>
          <AuthorityRenderer accept={[Roles.Admin]}>
            <Grid
              item
              sx={{
                paddingTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {drawerOpen ? (
                <Button
                  variant="outlined"
                  endIcon={<IconPlus />}
                  sx={{ width: '100%' }}
                  onClick={handleAddOrganiser}
                >
                  <IFormattedMessage id="actions.add_organiser" />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{ padding: '2px', minWidth: 'auto' }}
                  onClick={handleAddOrganiser}
                >
                  <IconPlus />
                </Button>
              )}
            </Grid>
          </AuthorityRenderer>
        </>
      )}
      {isAdding && (
        <>
          <OrganiserAddModal
            isOpen={isAdding}
            onClose={() => setIsAdding(false)}
            handleOrganiserSave={handleOrganiserSave}
          />
        </>
      )}
    </>
  );
};

export default OrganisersSideBar;
