import React from 'react';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import MainCard from '../cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';

import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import { useIntl } from 'react-intl';
import ITextField from 'ui-component/form/TextField';
import { Formik } from 'formik';
import AsyncActionButton from 'ui-component/form/actions/Async';
import Yup from 'utils/yup';

// modal position
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function OrganiserAddModal({
  isOpen = false,
  onClose = () => {},
  handleOrganiserSave = async () => {},
}) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const intl = useIntl();

  const [open, setOpen] = React.useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <MainCard
        sx={{
          position: 'absolute',
          width: { xs: 280, lg: 450 },
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          ...modalStyle,
        }}
        title={<IFormattedMessage id="modal.create_organiser.title" />}
        content={false}
        secondary={
          <IconButton onClick={handleClose} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Formik
          initialValues={{ name: '' }}
          onSubmit={async (values) => {
            try {
              await handleOrganiserSave(values);
            } catch (err) {
              console.error(err);
            }
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(255)
              .required(intl.formatMessage({ id: 'yup.name.required' })),
          })}
        >
          {({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <CardContent>
                <ITextField field="name" parent="organiser" />
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container justifyContent="flex-end">
                  <OrganiserAddModal />
                  <AsyncActionButton />
                </Grid>
              </CardActions>
            </form>
          )}
        </Formik>
      </MainCard>
    </Modal>
  );
}
