import {
  IconHeartHandshake,
  IconNotes,
  IconSpeakerphone,
  IconBubbleText,
} from '@tabler/icons-react';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import { AuthorityGroup } from 'utils/authorityGroup';

const icons = {
  IconHeartHandshake,
  IconNotes,
  IconSpeakerphone,
  IconBubbleText,
};

const contentManagement = {
  id: 'content-management',
  roles: [
    AuthorityGroup.CoOwner,
    AuthorityGroup.EventManager,
    AuthorityGroup.ContentManager,
  ],
  title: <IFormattedMessage id="menu.content-management" />,
  icon: icons.IconApps,
  type: 'group',
  children: [
    {
      id: 'speakers',
      title: <IFormattedMessage id="menu.speakers" />,
      type: 'item',
      icon: icons.IconSpeakerphone,
      url: '/speakers',
      event: true,
    },
    {
      id: 'programs',
      title: <IFormattedMessage id="menu.programs" />,
      type: 'item',
      icon: icons.IconNotes,
      url: '/program',
      event: true,
    },
    {
      id: 'sponsors',
      title: <IFormattedMessage id="menu.sponsors" />,
      type: 'item',
      icon: icons.IconHeartHandshake,
      url: '/sponsors',
      event: true,
    },
    {
      id: 'sss',
      title: <IFormattedMessage id="menu.sss" />,
      type: 'item',
      icon: icons.IconBubbleText,
      url: '/sss',
      event: true,
    },
  ],
};

export default contentManagement;
