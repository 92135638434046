import PropTypes from 'prop-types';
import { useEffect, useReducer } from 'react';
import { jwtDecode } from 'jwt-decode';
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { useIntl } from 'react-intl';
import { getLocaleConfig } from 'utils/date/getLocale';
import JWTContext from './JWTContext';
import useConfig from 'hooks/useConfig';
import { usePostHog } from 'posthog-js/react';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
};

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const intl = useIntl();
  const { i18n } = useConfig();
  const posthog = usePostHog();

  useEffect(() => {
    const init = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');

        if (accessToken && verifyToken(accessToken)) {
          setSession(accessToken, refreshToken);
          const response = await axios.get('/api/auth/me');
          const user = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
          posthog.identify(user?.id, {
            name:
              user?.name && user?.surname ? `${user.name} ${user.surname}` : '',
            email: user?.email,
          });
        } else if (accessToken && !verifyToken(accessToken)) {
          // Access token expired
          try {
            const rs = await axios.post('/api/auth/refresh', {
              accessToken: localStorage.getItem('accessToken'),
              refreshToken: localStorage.getItem('refreshToken'),
            });

            const data = rs.data;
            const { accessToken, refreshToken, user } = data;

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user,
              },
            });
          } catch (_error) {
            dispatch({
              type: LOGOUT,
            });
          }
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, [intl]);

  const updateMe = async () => {
    const response = await axios.get('/api/auth/me');
    const user = response.data;
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const login = async (email, password, rememberMe, invitationId) => {
    const response = await axios.post('/api/auth/login', {
      email,
      password,
      rememberMe,
      invitationId,
    });
    const { accessToken, refreshToken, user } = response.data;
    setSession(accessToken, refreshToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
    posthog.identify(user?.id, {
      name: user?.name && user?.surname ? `${user.name} ${user.surname}` : '',
      email: user?.email,
    });
    return true;
  };

  const otpLogin = async (email, otp, invitationId) => {
    const response = await axios.post('/api/auth/otp-login', {
      email,
      otp,
      invitationId,
    });
    const { accessToken, refreshToken, user } = response.data;
    setSession(accessToken, refreshToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
    posthog.identify(user?.id, {
      name: user?.name && user?.surname ? `${user.name} ${user.surname}` : '',
      email: user?.email,
    });
    return true;
  };

  const register = async (
    email,
    password,
    firstName,
    lastName,
    countryCallCode,
    phone,
    answers,
    invitationId,
    token,
    extra = {},
  ) => {
    const lang = i18n ?? getLocaleConfig();

    const res = await axios.post(
      Boolean(lang) ? `/api/auth/register?lang=${lang}` : '/api/auth/register',
      {
        email,
        password,
        invitationId,
        token,
        countryCallCode,
        phone,
        name: firstName,
        surname: lastName,
        ...extra,
        answers: JSON.stringify(
          answers.map((a) => ({
            ...a,
            question: undefined,
            questionId: a.question.id,
          })),
        ),
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res;
  };

  const logout = () => {
    setSession(null, null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email) => {
    const lang = i18n ?? getLocaleConfig();
    await axios.post(`/api/auth/forgot-password?lang=${lang}`, {
      email,
    });
  };

  const changePassword = async (data) => {
    const { email, password, passwordAgain, token } = data;
    const lang = i18n ?? getLocaleConfig();
    await axios.post(`/api/auth/reset-password?lang=${lang}`, {
      email,
      password,
      passwordAgain,
      token,
    });
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        updateMe,
        login,
        otpLogin,
        logout,
        register,
        resetPassword,
        changePassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
