import useUserAuthority from './useUserAuthority';
import { Roles } from 'utils/roles';

export default function AuthorityRenderer({
  children,
  accept,
  fallback,
  authority = null,
}) {
  const userAuthority = authority ?? useUserAuthority();

  if (!accept) return children;
  if (![Roles.Admin, ...accept].includes(userAuthority)) return <>{fallback}</>;
  return children;
}
