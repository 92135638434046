import { getLocaleConfig } from 'utils/date/getLocale';

export const DASHBOARD_PATH = '/events';
export const PROFILE_PATH = '/participant/profile';
export const ORGANISERS_PATH = '/organisers';
export const HORIZONTAL_MAX_ITEM = 7;

export const MenuOrientation = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const ThemeDirection = {
  LTR: 'ltr',
  RTL: 'rtl',
};

const config = {
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  mode: ThemeMode.LIGHT,
  presetColor: 'theme3',
  i18n: getLocaleConfig(),
  themeDirection: ThemeDirection.LTR,
  container: false,
};
export default config;
