import { useSnackbar } from 'notistack';

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export const snackbar = {
  close(snackId = null) {
    if (snackId) {
      useSnackbarRef.closeSnackbar(snackId);
    } else {
      useSnackbarRef.closeSnackbar();
    }
  },
  success(msg, autoHideDuration, actionCallback) {
    return this.toast(msg, 'success', autoHideDuration, actionCallback);
  },
  warning(msg, autoHideDuration, actionCallback) {
    return this.toast(msg, 'warning', autoHideDuration, actionCallback);
  },
  info(msg, autoHideDuration, actionCallback) {
    return this.toast(msg, 'info', autoHideDuration, actionCallback);
  },
  error(msg, autoHideDuration, actionCallback) {
    return this.toast(msg, 'error', autoHideDuration, actionCallback);
  },
  toast(msg, variant = 'default', autoHideDuration = 5000, action = null) {
    return useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration,
      action,
      preventDuplicate: true,
    });
  },
};

export default snackbar;
