import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const TicketPage = Loadable(lazy(() => import('views/pages/ticket')));

const ParticipantRoutesMinimal = {
  path: '/',
  element: (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: '#edf2f6',
      }}
    >
      <Outlet />
    </Box>
  ),
  children: [
    {
      // not participantNo
      path: '/ticket/:participantId/:templateId',
      element: <TicketPage />,
    },
  ],
};

export default ParticipantRoutesMinimal;
