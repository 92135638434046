import { Outlet, useLocation } from 'react-router-dom';
import { dispatch } from 'store';
import { closeSnackbar } from 'store/slices/snackbar';
import { useDeepEffect } from 'utils/hooks/useEffectHooks';
import snackbar from 'utils/snackbar';

const MinimalLayout = () => {
  const location = useLocation();
  useDeepEffect(() => {
    // Error snackbar
    dispatch(closeSnackbar());
    // Unsaved changes snackbar
    snackbar.close();
  }, [location?.pathname]);
  return (
    <>
      <Outlet />
    </>
  );
};

export default MinimalLayout;
