import { Button } from '@mui/material';
import React from 'react';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import Link from '@mui/material/Link';

const UserLogin = () => {
  return (
    <>
      <Button color="inherit" component={Link} href={`/participant/login`}>
        <IFormattedMessage id="menu.login" />
      </Button>
    </>
  );
};

export default UserLogin;
