import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import { IconCalendarCog } from '@tabler/icons-react';
import { AuthorityGroup } from 'utils/authorityGroup';

const icons = {
  IconCalendarCog,
};
const eventInformation = {
  id: 'event-dashboard',
  roles: [
    AuthorityGroup.CoOwner,
    AuthorityGroup.EventManager,
    AuthorityGroup.ContentManager,
    AuthorityGroup.Staff,
    AuthorityGroup.RegistrationAndSalesManager,
  ],
  title: <IFormattedMessage id="menu.event-information" />,
  icon: icons.IconCalendarCog,
  type: 'group',
  url: '/info',
  event: true,
};

export default eventInformation;
