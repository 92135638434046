import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

const CookiePolicy = Loadable(lazy(() => import('views/legal/CookiePolicy')));
const Gdpr = Loadable(lazy(() => import('views/legal/Gdpr')));
const Licenses = Loadable(lazy(() => import('views/legal/Licenses')));

const LegalRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/gdpr',
      element: <Gdpr />,
    },
    {
      path: '/cookie-policy',
      element: <CookiePolicy />,
    },
    {
      path: '/open-source-licenses',
      element: <Licenses />,
    },
  ],
};

export default LegalRoutes;
