import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  const theme = useTheme();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon sx={{ color: theme.palette.common.white }} />
    </IconButton>
  );
}

export default SnackbarCloseButton;
