import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';
import Notistack from 'ui-component/third-party/Notistack';
import ThemeCustomization from 'themes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { JWTProvider as AuthProvider } from 'contexts/JWTProvider';

const App = () => {
  return (
    <ThemeCustomization>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Locales>
          <NavigationScroll>
            <AuthProvider>
              <>
                <Notistack>
                  <RouterProvider router={router} />
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </NavigationScroll>
        </Locales>
      </LocalizationProvider>
    </ThemeCustomization>
  );
};

export default App;
