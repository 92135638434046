import dashboard from 'menu-items/dashboard';
import eventInformation from './event-information';
import register from './register/index';
import contentManagement from './content-management';
import utilities from './utilities';

const eventMenuItems = {
  items: [dashboard, eventInformation, register, contentManagement, utilities],
};

export default eventMenuItems;
