import { combineReducers } from 'redux';
import snackbarReducer from './slices/snackbar';
import organiserSlice from './slices/organiser/organiser';
import eventSlice from './slices/event/event';
import cartReducer from './slices/cart';
import printerReducer from './slices/printer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducer = combineReducers({
  snackbar: snackbarReducer,
  organiser: persistReducer(
    {
      key: 'organiser',
      storage,
      keyPrefix: 'kapital-event-',
    },
    organiserSlice,
  ),
  event: persistReducer(
    {
      key: 'event',
      storage,
      keyPrefix: 'kapital-event-',
    },
    eventSlice,
  ),
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'kapital-event-',
    },
    cartReducer,
  ),
  printer: persistReducer(
    {
      key: 'printer',
      storage,
      keyPrefix: 'kapital-event-',
    },
    printerReducer,
  ),
});

export default reducer;
