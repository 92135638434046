import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import currency from 'currency.js';
import { sum } from 'lodash-es';
import snackbar from 'utils/snackbar';
import intl from 'utils/intl';
import { formattedTranslationValue } from 'utils/formatted-translation-value';
import TagManager from 'react-gtm-module';

const initialState = {
  error: null,
  open: false,
  formId: null,
  event: {},
  checkout: {
    currencySymbol: '₺',
    wireEnabled: false,
    onlineEnabled: false,
    pricePeriods: {},
    step: 0,
    initialPrices: {
      subtotal: 0,
      discount: 0,
      vatValue: 0,
      total: 0,
    },
    subtotal: 0,
    discount: 0,
    total: 0,
    vatValue: 0,
    shipping: 0,
    billing: null,
    vat: null,
    coupon: null,
    // this comes from query parameters
    couponCode: null,
    // this goes with createOrder
    couponId: null,
    payment: {
      method: null,
      card: {},
    },
  },
};

const pushAddToCartToDataLayer = (pp, pricePeriods) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'kapital_events_add_to_cart',
      kapital_events_event_name: pp.eventName,
      kapital_events_cart_items: pricePeriods.map((pp) => ({
        content_id: pp.package.id,
        content_name: formattedTranslationValue(
          pp.package.translations,
          'name',
          'tr',
        ),
        num_items: pp.amount,
        content_price: pp.price,
      })),
      kapital_events_cart_count: sum(pricePeriods.map((pp) => pp.amount)),
      kapital_events_cart_total: sum(
        pricePeriods.map((pp) => pp.total.value),
      ).toFixed(2),
      kapital_events_cart_currency: pp.currencyCode,
    },
  });
};

// Returns the number of items in the cart that the applied coupon modifies
const getNumberofSelectiveDiscounts = (pricePeriods, coupon) =>
  Object.values(pricePeriods).filter((pp) =>
    coupon.couponGroupPackages.some(
      (cgp) => cgp.package.id === pp.package.id && cgp.selective,
    ),
  ).length;

const calculateTotal = (pricePeriods, coupon) => {
  const updatedPricePeriods = {};
  Object.values(pricePeriods).forEach((pp) => {
    // If the coupon is of fixed type and no selective packages are selected, it will only apply at the final price
    if (
      coupon?.type === 'fixed' &&
      coupon.couponGroupPackages.every((cgp) => !Boolean(cgp.selective))
    ) {
      updatedPricePeriods[pp.id] = pp;
      return;
    }

    // Otherwise the {pricePeriods} will be updated to display the {newTotal} of each item
    updatedPricePeriods[pp.id] = {
      ...pp,
      total: currency(pp.price).multiply(pp.amount),
      discount:
        Boolean(coupon) &&
        (coupon.couponGroupPackages.find(
          (cgp) => cgp.package.id === pp.package.id && cgp.selective,
        ) ||
          coupon.couponGroupPackages.every((cgp) => !Boolean(cgp.selective)))
          ? currency(pp.price)
              .multiply(pp.amount)
              .subtract(
                coupon.type === 'fixed'
                  ? currency(coupon.amount)
                  : currency(pp.price)
                      .multiply(pp.amount)
                      .multiply(currency(coupon.amount).divide(100)),
              )
          : currency(0),
      newTotal:
        Boolean(coupon) &&
        (coupon.couponGroupPackages.find(
          (cgp) => cgp.package.id === pp.package.id && cgp.selective,
        ) ||
          coupon.couponGroupPackages.every((cgp) => !Boolean(cgp.selective)))
          ? currency(pp.price)
              .multiply(pp.amount)
              .subtract(
                coupon.type === 'fixed'
                  ? currency(coupon.amount)
                  : currency(pp.price)
                      .multiply(pp.amount)
                      .multiply(currency(coupon.amount).divide(100)),
              )
          : null,
    };
  });

  return [
    updatedPricePeriods,
    // Total price of the cart
    Object.values(pricePeriods).reduce(
      (prev, cur) =>
        prev.add(
          Boolean(coupon) &&
            (coupon.couponGroupPackages.find(
              (cgp) => cgp.package.id === cur.package.id && cgp.selective,
            ) ||
              (coupon.couponGroupPackages.every(
                (cgp) => !Boolean(cgp.selective),
              ) &&
                coupon.type === 'percentage'))
            ? currency(cur.price)
                .multiply(cur.amount)
                .subtract(
                  coupon.type === 'fixed'
                    ? currency(coupon.amount)
                    : currency(cur.price)
                        .multiply(cur.amount)
                        .multiply(currency(coupon.amount).divide(100)),
                )
            : currency(cur.price).multiply(cur.amount),
        ),
      currency(0),
    ),
  ];
};

const calculatePrices = (pricePeriods, vat, coupon) => {
  const [updatedPricePeriods, totalPrice] = calculateTotal(
    pricePeriods,
    coupon,
  );

  const { ratio, enabled } = vat ?? {
    ratio: 20,
    enabled: false,
  };
  const vattedSubtotal = currency(totalPrice)
    .multiply(100)
    .divide(currency(ratio).add(100)).value;
  const vatValue = currency(totalPrice).subtract(
    currency(totalPrice).multiply(100).divide(currency(ratio).add(100)),
  ).value;
  const initialPrices = {
    subtotal: enabled ? vattedSubtotal : totalPrice,
    total: totalPrice,
    discount: 0,
    vatValue: enabled ? vatValue : 0,
  };
  const prices = {
    subtotal: enabled ? vattedSubtotal : totalPrice,
    total: totalPrice,
    discount: 0,
    vatValue: enabled ? vatValue : 0,
  };
  if (enabled) {
    prices.subtotal = vattedSubtotal;
    prices.vatValue = vatValue;
  } else {
    prices.vatValue = 0;
    prices.subtotal = totalPrice;
  }

  const discountNoVat =
    (coupon &&
      (coupon.amount <= 0
        ? currency(0).value
        : coupon.type === 'fixed'
          ? currency(coupon.amount).value
          : currency(prices.subtotal).multiply(
              currency(coupon.amount).divide(100),
            ).value)) ||
    0;
  if (coupon) {
    const numOfSelectiveDiscounts = getNumberofSelectiveDiscounts(
      pricePeriods,
      coupon,
    );
    let discount = currency(0);

    if (coupon.amount <= 0) {
      discount = currency(0);
    } else if (numOfSelectiveDiscounts > 0) {
      if (coupon.type === 'fixed') {
        if (enabled) {
          discount = currency(coupon.amount)
            .multiply(numOfSelectiveDiscounts)
            .divide(currency(1).add(currency(ratio).divide(100)));
        } else {
          discount = currency(coupon.amount).multiply(numOfSelectiveDiscounts);
        }
      } else if (coupon.type === 'percentage') {
        discount = Object.values(updatedPricePeriods).reduce(
          (prev, cur) => prev.add(currency(cur.discount)),
          currency(0),
        );
      }
    } else if (numOfSelectiveDiscounts === 0) {
      if (coupon.type === 'fixed') {
        if (enabled) {
          discount = currency(coupon.amount).divide(
            currency(1).add(currency(ratio).divide(100)),
          );
        } else {
          discount = currency(coupon.amount);
        }
      } else if (coupon.type === 'percentage') {
        discount = currency(prices.subtotal).multiply(
          currency(coupon.amount).divide(100),
        );
      }
    }
    prices.discount = discount.value;
    const sub =
      coupon.type === 'fixed' &&
      coupon.couponGroupPackages.every((cgp) => !Boolean(cgp.selective))
        ? currency(prices.subtotal).subtract(discount).value
        : currency(prices.subtotal).value;
    prices.subtotal = sub;
    const vatVal = enabled
      ? currency(sub).multiply(currency(ratio).divide(100)).value
      : 0;
    prices.vatValue = vatVal;
    prices.total = currency(sub).add(currency(vatVal)).value;
  } else {
    prices.total = totalPrice;
  }

  // If the discount covers the entire cost update the values accordingly
  const discountGtTotal = discountNoVat >= initialPrices.total;
  prices.discount = discountGtTotal ? initialPrices.total : prices.discount;
  prices.subtotal = discountGtTotal ? initialPrices.subtotal : prices.subtotal;
  prices.total = discountGtTotal ? 0 : prices.total;
  prices.vatValue = discountGtTotal ? initialPrices.vatValue : prices.vatValue;

  return {
    initialPrices,
    prices: coupon ? prices : initialPrices,
    updatedPricePeriods,
  };
};

const validateStock = (state, pp) => {
  if (!Boolean(pp)) return;
  const pkg = pp.package;
  const stock = pkg.stock;
  const prevOrdsAmount = sum(
    pkg.pricePeriods.flatMap((pp) =>
      pp.orderPricePeriods.map((opp) => opp.amount),
    ),
  );
  const curOrdAmount = (state.checkout.pricePeriods[pp?.id]?.amount ?? 0) + 1;
  const totalAmount = prevOrdsAmount + curOrdAmount;
  const inStock = !((stock && stock < totalAmount) || pkg?.noStock);
  if (!inStock) {
    snackbar.warning(intl().formatMessage({ id: 'API.ticket_out_of_stock' }));
  }
  return inStock;
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setFormId(state, action) {
      state.formId = action.payload;
    },
    setCoupon(state, action) {
      const coupon = action.payload;
      state.checkout.coupon = coupon;
      state.checkout.couponId = coupon?.id;

      const { initialPrices, prices, updatedPricePeriods } = calculatePrices(
        state.checkout.pricePeriods,
        state.checkout.vat,
        state.checkout.coupon,
      );
      const { subtotal, total, discount, vatValue } = prices;

      state.checkout.pricePeriods = updatedPricePeriods;
      state.checkout.initialPrices = initialPrices;
      state.checkout = {
        ...state.checkout,
        subtotal,
        total,
        discount,
        vatValue,
      };
    },
    addPricePeriod(state, action) {
      const pp = action.payload.pricePeriod;
      if (!validateStock(state, pp)) return;

      state.checkout.pricePeriods[pp.id] = {
        ...pp,
        amount: !Object.keys(state.checkout.pricePeriods).includes(pp.id)
          ? 1
          : state.checkout.pricePeriods[pp.id].amount + 1,
      };
      const { initialPrices, prices, updatedPricePeriods } = calculatePrices(
        state.checkout.pricePeriods,
        state.checkout.vat,
        state.checkout.coupon,
      );
      const { subtotal, total, discount, vatValue } = prices;

      state.checkout.pricePeriods = updatedPricePeriods;
      state.checkout.initialPrices = initialPrices;
      state.checkout = {
        ...state.checkout,
        subtotal,
        total,
        discount,
        vatValue,
      };

      pushAddToCartToDataLayer(pp, Object.values(state.checkout.pricePeriods));
    },
    decrementPricePeriod(state, action) {
      const pp = action.payload.pricePeriod;
      state.checkout.pricePeriods[pp.id] = {
        ...pp,
        amount: !Object.keys(state.checkout.pricePeriods).includes(pp.id)
          ? 1
          : state.checkout.pricePeriods[pp.id].amount - 1,
      };
      if (state.checkout.pricePeriods[pp.id].amount <= 0)
        delete state.checkout.pricePeriods[pp.id];

      const { initialPrices, prices, updatedPricePeriods } = calculatePrices(
        state.checkout.pricePeriods,
        state.checkout.vat,
        state.checkout.coupon,
      );
      const { subtotal, total, discount, vatValue } = prices;

      state.checkout.pricePeriods = updatedPricePeriods;
      state.checkout.initialPrices = initialPrices;
      state.checkout = {
        ...state.checkout,
        subtotal,
        total,
        discount,
        vatValue,
      };

      pushAddToCartToDataLayer(pp, Object.values(state.checkout.pricePeriods));
    },
    incrementPricePeriod(state, action) {
      const pp = action.payload.pricePeriod;
      if (!validateStock(state, pp)) return;

      state.checkout.pricePeriods[pp.id] = {
        ...pp,
        amount: !Object.keys(state.checkout.pricePeriods).includes(pp.id)
          ? 1
          : state.checkout.pricePeriods[pp.id].amount + 1,
      };

      const { initialPrices, prices, updatedPricePeriods } = calculatePrices(
        state.checkout.pricePeriods,
        state.checkout.vat,
        state.checkout.coupon,
      );
      const { subtotal, total, discount, vatValue } = prices;

      state.checkout.pricePeriods = updatedPricePeriods;
      state.checkout.initialPrices = initialPrices;
      state.checkout = {
        ...state.checkout,
        subtotal,
        total,
        discount,
        vatValue,
      };

      pushAddToCartToDataLayer(pp, Object.values(state.checkout.pricePeriods));
    },
    removePricePeriod(state, action) {
      const pp = action.payload.pricePeriod;
      delete state.checkout.pricePeriods[pp.id];

      const { initialPrices, prices, updatedPricePeriods } = calculatePrices(
        state.checkout.pricePeriods,
        state.checkout.vat,
        state.checkout.coupon,
      );
      const { subtotal, total, discount, vatValue } = prices;

      state.checkout.pricePeriods = updatedPricePeriods;
      state.checkout.initialPrices = initialPrices;
      state.checkout = {
        ...state.checkout,
        subtotal,
        total,
        discount,
        vatValue,
      };

      pushAddToCartToDataLayer(pp, Object.values(state.checkout.pricePeriods));
    },
    setPricePeriods(state, action) {
      state.checkout.pricePeriods = action.payload;

      const { initialPrices, prices, updatedPricePeriods } = calculatePrices(
        state.checkout.pricePeriods,
        state.checkout.vat,
        state.checkout.coupon,
      );
      const { subtotal, total, discount, vatValue } = prices;

      state.checkout.pricePeriods = updatedPricePeriods;
      state.checkout.initialPrices = initialPrices;
      state.checkout = {
        ...state.checkout,
        subtotal,
        total,
        discount,
        vatValue,
      };
    },
    filterPricePeriods(state, action) {
      const validIds = action.payload;

      // Filter out pricperiods that are not in the validIds array
      state.checkout.pricePeriods = Object.fromEntries(
        validIds.map((validId) => [
          validId,
          state.checkout.pricePeriods[validId],
        ]),
      );

      const { initialPrices, prices, updatedPricePeriods } = calculatePrices(
        state.checkout.pricePeriods,
        state.checkout.vat,
        state.checkout.coupon,
      );
      const { subtotal, total, discount, vatValue } = prices;

      state.checkout.pricePeriods = updatedPricePeriods;
      state.checkout.initialPrices = initialPrices;
      state.checkout = {
        ...state.checkout,
        subtotal,
        total,
        discount,
        vatValue,
      };
    },
    setStep(state, action) {
      state.checkout.step = action.payload;
    },
    setNextStep(state) {
      state.checkout.step += 1;
    },
    setBackStep(state) {
      state.checkout.step -= 1;
    },
    resetCart(state) {
      state.checkout = initialState.checkout;
    },
    setBillingAddress(state, action) {
      state.checkout.billing = action.payload.billing;
    },
    setPaymentMethod(state, action) {
      state.checkout.payment = {
        ...state.checkout.payment,
        method: action.payload.method,
      };
    },
    setPaymentCard(state, action) {
      state.checkout.payment = {
        ...state.checkout.payment,
        card: action.payload.card,
      };
    },
    toggleCart(state, _) {
      state.open = !state.open;
    },
    openCart(state, _) {
      state.open = true;
    },
    closeCart(state, _) {
      state.open = false;
    },
    setCurrencySymbol(state, action) {
      state.checkout.currencySymbol = action.payload;
    },
    setCurrency(state, action) {
      state.checkout.currency = action.payload;
    },
    setWireEnabled(state, action) {
      state.checkout.wireEnabled = action.payload;
    },
    setOnlineEnabled(state, action) {
      state.checkout.onlineEnabled = action.payload;
    },
    setVat(state, action) {
      state.checkout.vat = action.payload;
    },
    setEvent(state, action) {
      state.event = action.payload;
    },
    setCouponCode(state, action) {
      state.checkout.couponCode = action.payload;
    },
    recalculatePrices(state, _) {
      const { initialPrices, prices, updatedPricePeriods } = calculatePrices(
        state.checkout.pricePeriods,
        state.checkout.vat,
        state.checkout.coupon,
      );
      const { subtotal, total, discount, vatValue } = prices;

      state.checkout.pricePeriods = updatedPricePeriods;
      state.checkout.initialPrices = initialPrices;
      state.checkout = {
        ...state.checkout,
        subtotal,
        total,
        discount,
        vatValue,
      };
    },
  },
});

export default slice.reducer;

export function addPricePeriod(pricePeriod) {
  return async () => {
    dispatch(slice.actions.addPricePeriod({ pricePeriod }));
  };
}

export function decrementPricePeriod(pricePeriod) {
  return async () => {
    dispatch(slice.actions.decrementPricePeriod({ pricePeriod }));
  };
}

export function incrementPricePeriod(pricePeriod) {
  return async () => {
    dispatch(slice.actions.incrementPricePeriod({ pricePeriod }));
  };
}

export function removePricePeriod(pricePeriod) {
  return async () => {
    dispatch(slice.actions.removePricePeriod({ pricePeriod }));
  };
}

export function setPricePeriods(pricePeriods) {
  return async () => {
    dispatch(slice.actions.setPricePeriods(pricePeriods));
  };
}

export function setStep(step) {
  return () => {
    dispatch(slice.actions.setStep(step));
  };
}

export function setNextStep() {
  return () => {
    dispatch(slice.actions.setNextStep({}));
  };
}

export function setBackStep() {
  return () => {
    dispatch(slice.actions.setBackStep({}));
  };
}

export function setBillingAddress(billing) {
  return async () => {
    dispatch(slice.actions.setBillingAddress({ billing }));
  };
}

export function setDiscount(code, total) {
  return async () => {
    dispatch(slice.actions.setDiscount({ code, total }));
  };
}

export function setPaymentMethod(method) {
  return async () => {
    dispatch(slice.actions.setPaymentMethod({ method }));
  };
}

export function setPaymentCard(card) {
  return async () => {
    dispatch(slice.actions.setPaymentCard({ card }));
  };
}

export function resetCart() {
  return async () => {
    dispatch(slice.actions.resetCart());
  };
}

export function toggleCart() {
  return async () => {
    dispatch(slice.actions.toggleCart());
  };
}

export function openCart() {
  return async () => {
    dispatch(slice.actions.openCart());
  };
}

export function closeCart() {
  return async () => {
    dispatch(slice.actions.closeCart());
  };
}

export function setCurrencySymbol(symbol) {
  return () => {
    dispatch(slice.actions.setCurrencySymbol(symbol));
  };
}

export function setCurrency(symbol) {
  return () => {
    dispatch(slice.actions.setCurrency(symbol));
  };
}

export function setWireEnabled(enabled) {
  return () => {
    dispatch(slice.actions.setWireEnabled(enabled));
  };
}

export function setOnlineEnabled(enabled) {
  return () => {
    dispatch(slice.actions.setOnlineEnabled(enabled));
  };
}

export function setVat(data) {
  return async () => {
    dispatch(slice.actions.setVat(data));
  };
}

export function setEvent(data) {
  return async () => {
    dispatch(slice.actions.setEvent(data));
  };
}

export function setFormId(formId) {
  return async () => {
    dispatch(slice.actions.setFormId(formId));
  };
}

export function setCoupon(coupon) {
  return async () => {
    dispatch(slice.actions.setCoupon(coupon));
  };
}

export function recalculatePrices() {
  return async () => {
    dispatch(slice.actions.recalculatePrices());
  };
}

export function setCouponCode(couponCode) {
  return async () => {
    dispatch(slice.actions.setCouponCode(couponCode));
  };
}

export function filterPricePeriods(validIds) {
  return async () => {
    dispatch(slice.actions.filterPricePeriods(validIds));
  };
}
