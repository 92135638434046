import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'email', function validateEmail(message) {
  return this.matches(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, {
    message,
    name: 'email',
    excludeEmptyString: true,
  });
});

export default Yup;
