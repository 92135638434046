import PropTypes from 'prop-types';
import { cloneElement, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Logo from 'ui-component/Logo';
import { ThemeMode } from 'config';
import { IconHome2 } from '@tabler/icons-react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import UserLogin from './User';
import useAuth from 'hooks/useAuth';
import { useSelector } from 'store';
import LocalizationSection from 'layout/MainLayout/Header/LocalizationSection';

// elevation scroll
function ElevationScroll({ children, window }) {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return cloneElement(children, {
    elevation: trigger ? 1 : 0,
    style: {
      backgroundColor:
        theme.palette.mode === ThemeMode.DARK && trigger
          ? theme.palette.dark[800]
          : theme.palette.background.default,
      color: theme.palette.text.dark,
      position: 'static',
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.node,
  window: PropTypes.object,
};

const AppBar = ({ ...others }) => {
  const [drawerToggle, setDrawerToggle] = useState(false);
  const { user, logout } = useAuth();
  const lastVisitedFormId = useSelector(
    (state) => state.event.lastVisitedFormId,
  );

  const drawerToggler = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerToggle(open);
  };

  const pathname = window.location.pathname.split('/');

  return (
    <ElevationScroll {...others}>
      <MuiAppBar sx={{ position: 'static' }}>
        <Container>
          <Toolbar sx={{ py: 2.5, px: `0 !important` }}>
            <Typography sx={{ flexGrow: 1, textAlign: 'left' }}>
              <RouterLink
                to={
                  lastVisitedFormId
                    ? `/events/register/${lastVisitedFormId}`
                    : user
                      ? `/participant/profile/${user?.id}`
                      : `/participant/login`
                }
              >
                <Logo />
              </RouterLink>
            </Typography>
            <Stack
              direction="row"
              sx={{
                display: { xs: 'none', sm: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
              }}
              spacing={{ xs: 1.5, md: 2.5 }}
            >
              {(!user && <UserLogin />) || (
                <Button color="inherit" onClick={async () => await logout()}>
                  <IFormattedMessage id="actions.logout" />
                </Button>
              )}
              <LocalizationSection />
            </Stack>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <IconButton
                color="inherit"
                onClick={drawerToggler(true)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="top"
                open={drawerToggle}
                onClose={drawerToggler(false)}
              >
                {drawerToggle && (
                  <Box
                    sx={{ width: 'auto' }}
                    role="presentation"
                    onClick={drawerToggler(false)}
                    onKeyDown={drawerToggler(false)}
                  >
                    <List>
                      <Link
                        sx={{ textDecoration: 'none' }}
                        href={
                          `/` +
                          pathname[1] +
                          `/` +
                          pathname[2] +
                          `/` +
                          pathname[3]
                        }
                      >
                        <ListItemButton component="a">
                          <ListItemIcon>
                            <IconHome2 />
                          </ListItemIcon>
                          <ListItemText
                            primary={<IFormattedMessage id="menu.home" />}
                          />
                        </ListItemButton>
                      </Link>
                      <Link
                        sx={{ textDecoration: 'none' }}
                        href={
                          `/` +
                          pathname[1] +
                          `/` +
                          pathname[2] +
                          `/` +
                          pathname[3] +
                          `/checkout`
                        }
                      >
                        <ListItemButton component="a">
                          <ListItemIcon>
                            <ShoppingCartIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={<IFormattedMessage id="menu.cart" />}
                          />
                        </ListItemButton>
                      </Link>
                    </List>
                  </Box>
                )}
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </MuiAppBar>
    </ElevationScroll>
  );
};

export default AppBar;
