import { IconHome } from '@tabler/icons-react';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';

const icons = {
  IconHome,
};
const dashboard = {
  id: 'events-page',
  title: <IFormattedMessage id="menu.dashboard" />,
  icon: icons.IconHome,
  type: 'group',
  url: '/dashboard',
};

export default dashboard;
