import {
  IconShieldHalfFilled,
  IconCookieFilled,
  IconLicense,
} from '@tabler/icons-react';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';

const icons = {
  IconShieldHalfFilled,
  IconCookieFilled,
  IconLicense,
};

const pages = {
  id: 'legal',
  title: <IFormattedMessage id="legal" />,
  icon: icons.IconKey,
  type: 'group',
  children: [
    {
      id: 'gdpr',
      title: <IFormattedMessage id="gdpr" />,
      type: 'item',
      icon: icons.IconShieldHalfFilled,
      url: '/gdpr',
    },
    {
      id: 'cookie-policy',
      title: <IFormattedMessage id="cookie-policy" />,
      type: 'item',
      icon: icons.IconCookieFilled,
      url: '/cookie-policy',
    },
    {
      id: 'open-source-licenses',
      title: <IFormattedMessage id="open-source-licenses" />,
      type: 'item',
      icon: icons.IconLicense,
      url: '/open-source-licenses',
    },
  ],
};

export default pages;
