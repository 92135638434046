import { IconCashRegister } from '@tabler/icons-react';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';

const icons = {
  IconCashRegister,
};
const orders = {
  id: 'orders-page',
  title: <IFormattedMessage id="orders-page" />,
  icon: icons.IconCashRegister,
  type: 'group',
  url: '/orders-page',
};

export default orders;
