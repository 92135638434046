import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import axios from 'utils/axios';
import snackbar from 'utils/snackbar';

export const ORGANISER = 'organisers';

export const Services = {
  getAll: async () => await axios.get('api/organiser').then((res) => res.data),
  getOneById: async (id) =>
    await axios.get(`api/organiser/${id}`).then((res) => res.data),
  createRecord: async (data) =>
    await axios.post(`api/organiser`, data).then((res) => res.data),
  deleteRecord: async (id) =>
    await axios.delete(`api/organiser/${id}`).then((res) => res.data),
  updateRecord: async (id, data) =>
    await axios
      .patch(`api/organiser/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data),
  uninviteMember: async (inviteId, organiserId) =>
    await axios
      .delete(`api/invitation/${organiserId}/${inviteId}`)
      .then((res) => res.data),
  inviteMember: async (organiserId, data) =>
    await axios
      .post(`api/invitation/${organiserId}`, data)
      .then((res) => res.data),
  getInviteById: async (id) =>
    await axios.get(`api/invitation/${id}`).then((res) => res.data),
  acceptInvitation: async (id) =>
    await axios.get(`api/invitation/${id}/accept`).then((res) => res.data),
  dismissInvitation: async (id) =>
    await axios.get(`api/invitation/${id}/dismiss`).then((res) => res.data),
  updateInvitation: async (id, organiserId, data) =>
    await axios
      .patch(`api/invitation/${organiserId}/${id}`, data)
      .then((res) => res.data),
  getCurrencies: async (id) =>
    await axios.get(`api/organiser/currency/${id}`).then((res) => res.data),
  getOwnerId: async (id) =>
    await axios.get(`api/organiser/owner/${id}`).then((res) => res.data),
};

export function useGetOrganisers() {
  const { data, isLoading } = useQuery({
    queryKey: [ORGANISER],
    queryFn: Services.getAll,
  });
  return {
    data,
    dataLoading: isLoading,
  };
}

export function useGetInvite(id) {
  const { data, isLoading } = useQuery({
    queryKey: ['invite', id],
    queryFn: () => Services.getInviteById(id),
    enabled: !!id,
  });

  return {
    data,
    dataLoading: isLoading,
  };
}

export function useGetOrganiser(id) {
  const { data, isLoading, isError } = useQuery({
    queryKey: [ORGANISER, id],
    queryFn: () => Services.getOneById(id),
    enabled: !!id,
  });

  return {
    data,
    dataLoading: isLoading,
    isError,
  };
}

export const useCreateOrganiser = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  return useMutation({
    mutationFn: Services.createRecord,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORGANISER] });
      snackbar.success(intl.formatMessage({ id: 'organiser.created' }));
    },
  });
};

export function useDeleteOrganiser() {
  const queryClient = useQueryClient();
  const intl = useIntl();

  return useMutation({
    mutationFn: Services.deleteRecord,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORGANISER] });
      snackbar.success(intl.formatMessage({ id: 'organiser.deleted' }));
    },
  });
}

export const useUpdateOrganiser = (id) => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  return useMutation({
    mutationFn: (data) => Services.updateRecord(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORGANISER, id] });
      snackbar.success(intl.formatMessage({ id: 'organiser.updated' }));
    },
  });
};

export function useUninviteMember(id, organiserId) {
  const queryClient = useQueryClient();
  const intl = useIntl();

  return useMutation({
    mutationFn: () => Services.uninviteMember(id, organiserId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORGANISER] });
      snackbar.success(intl.formatMessage({ id: 'user.uninvited' }));
    },
  });
}

export function useInviteMember(id) {
  const queryClient = useQueryClient();
  const intl = useIntl();

  return useMutation({
    mutationFn: (data) => Services.inviteMember(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORGANISER] });
      snackbar.success(intl.formatMessage({ id: 'user.invited' }));
    },
  });
}

export function useAcceptInvitation() {
  const intl = useIntl();

  return useMutation({
    mutationFn: Services.acceptInvitation,
    onSuccess: () => {
      snackbar.success(intl.formatMessage({ id: 'invite.accepted' }));
    },
  });
}

export function useDismissInvitation() {
  const intl = useIntl();

  return useMutation({
    mutationFn: Services.dismissInvitation,
    onSuccess: () => {
      snackbar.success(intl.formatMessage({ id: 'invite.dismissed' }));
    },
  });
}

export function useUpdateInvitation(organiserId, onSuccess = () => {}) {
  const queryClient = useQueryClient();
  const intl = useIntl();

  return useMutation({
    mutationFn: ({ id, eventIds, authorityGroup }) =>
      Services.updateInvitation(id, organiserId, {
        eventIds,
        authorityGroup,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORGANISER] });
      snackbar.success(intl.formatMessage({ id: 'invitation.updated' }));
      onSuccess();
    },
  });
}

export function useGetCurrencies(organiserId) {
  return useQuery({
    queryKey: [ORGANISER, organiserId, 'currencies'],
    queryFn: () => Services.getCurrencies(organiserId),
  });
}

export function useGetOwnerId(organiserId) {
  return useQuery({
    queryKey: [ORGANISER, organiserId, 'owner'],
    queryFn: () => Services.getOwnerId(organiserId),
    enabled: !!organiserId,
    staleTime: Infinity,
  });
}
