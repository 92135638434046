import { FormikContext, setNestedObjectValues } from 'formik';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useContext } from 'react';
import useUserAuthority from 'hooks/useUserAuthority';
import { AuthorityGroup } from 'utils/authorityGroup';
import { Roles } from 'utils/roles';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';

export default function AsyncActionButton({
  formik = null,
  size = 'large',
  icon = <SaveIcon />,
  disabled = false,
  loading = false,
  iconPosition = 'start',
  label = 'save',
  onClick = null,
  variant = 'contained',
  props = {},
  sx = {},
}) {
  const { isSubmitting, initialStatus, validateForm, setTouched, submitForm } =
    formik ??
      useContext(FormikContext) ?? {
        initialStatus: {},
        isSubmitting: false,
        validateForm: () => {},
        setTouched: () => {},
        submitForm: () => {},
      };

  const { allow } = initialStatus ?? {};
  const allowed = allow
    ? [AuthorityGroup.Owner, Roles.Admin, ...allow].includes(useUserAuthority())
    : true;
  const inputDisabled = disabled || !allowed || isSubmitting;

  return (
    <LoadingButton
      loading={loading || (formik?.isSubmitting ?? isSubmitting)}
      loadingPosition={icon !== null ? iconPosition : 'center'}
      {...(iconPosition === 'start' ? { startIcon: icon } : { endIcon: icon })}
      size={size}
      onClick={async () => {
        if (onClick) onClick();
        else {
          const validationErrors = await validateForm();
          if (Object.keys(validationErrors).length > 0) {
            setTouched(setNestedObjectValues(validationErrors, true));
          } else {
            await submitForm();
          }
        }
      }}
      disabled={inputDisabled}
      variant={variant}
      sx={sx}
      {...props}
    >
      <IFormattedMessage id={`actions.${label}`} />
    </LoadingButton>
  );
}
