import pages from './pages';
import organisersPage from './organisers-page';
import dashboard from './dashboard';
import orders from './orders';

const menuItems = {
  items: [dashboard, organisersPage, orders, pages],
};

export default menuItems;
