import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const Error404 = Loadable(lazy(() => import('views/error/Error404')));
const ErrorUnauthorized = Loadable(
  lazy(() => import('views/error/ErrorUnauthorized')),
);

const RegisterRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '*',
      element: <Error404 />,
    },
    {
      path: '/404',
      element: <Error404 />,
    },
    {
      path: '/unauthorized',
      element: <ErrorUnauthorized />,
    },
  ],
};

export default RegisterRoutes;
