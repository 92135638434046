import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';
import useAuth from 'hooks/useAuth';
import { Roles } from 'utils/roles';
import { ORGANISERS_PATH } from 'config';

const LogoSection = () => {
  const { user } = useAuth();

  return (
    <Link
      component={RouterLink}
      to={user?.role === Roles.Admin ? ORGANISERS_PATH : DASHBOARD_PATH}
      aria-label="theme-logo"
    >
      <Logo />
    </Link>
  );
};

export default LogoSection;
