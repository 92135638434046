import {
  IconPasswordUser,
  IconFileExport,
  IconClick,
} from '@tabler/icons-react';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import { AuthorityGroup } from 'utils/authorityGroup';

const icons = { IconPasswordUser, IconFileExport, IconClick };

const register = {
  id: 'register-sales-management',
  title: <IFormattedMessage id="menu.register-sales-management" />,
  icon: icons.IconPasswordUser,
  type: 'group',
  event: true,
  children: [
    {
      id: 'participant-management',
      title: <IFormattedMessage id="menu.participant-sub" />,
      type: 'collapse',
      icon: icons.IconPasswordUser,
      event: true,
      children: [
        {
          id: 'menu-participant-search',
          roles: [
            AuthorityGroup.CoOwner,
            AuthorityGroup.EventManager,
            AuthorityGroup.Staff,
          ],
          title: <IFormattedMessage id="menu.register.search" />,
          type: 'item',
          url: '/search',
          event: true,
        },
        {
          id: 'menu-participant-list',
          roles: [
            AuthorityGroup.CoOwner,
            AuthorityGroup.EventManager,
            AuthorityGroup.RegistrationAndSalesManager,
            AuthorityGroup.ContentManager,
          ],
          title: <IFormattedMessage id="menu.register.list" />,
          type: 'item',
          url: '/participants',
          event: true,
        },
        {
          id: 'menu-vip-search',
          roles: [
            AuthorityGroup.CoOwner,
            AuthorityGroup.EventManager,
            AuthorityGroup.Staff,
          ],
          title: <IFormattedMessage id="menu.register.vipSearch" />,
          type: 'item',
          url: '/vip-search',
          event: true,
        },
        {
          id: 'menu-tag',
          roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
          title: <IFormattedMessage id="menu.register.tags" />,
          type: 'item',
          url: '/tags',
          event: true,
        },
      ],
    },
    {
      id: 'register-management',
      roles: [
        AuthorityGroup.CoOwner,
        AuthorityGroup.EventManager,
        AuthorityGroup.RegistrationAndSalesManager,
      ],
      title: <IFormattedMessage id="menu.register-sub-title" />,
      type: 'collapse',
      icon: icons.IconClick,
      children: [
        {
          id: 'menu-register-sales-sub',
          roles: [
            AuthorityGroup.CoOwner,
            AuthorityGroup.EventManager,
            AuthorityGroup.RegistrationAndSalesManager,
          ],
          title: <IFormattedMessage id="menu.register.sales" />,
          type: 'item',
          url: '/sales',
          event: true,
        },
        {
          id: 'menu-register-participant',
          roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
          title: <IFormattedMessage id="menu.register.forms" />,
          type: 'item',
          url: '/registration-forms',
          event: true,
        },
        {
          id: 'menu-register-coupon',
          roles: [AuthorityGroup.CoOwner, AuthorityGroup.EventManager],
          title: <IFormattedMessage id="menu.register.coupons" />,
          type: 'item',
          url: '/coupons',
          event: true,
        },
      ],
    },
  ],
};

export default register;
