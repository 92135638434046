import PrintIcon from '@mui/icons-material/Print';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setPrinterId } from 'store/slices/printer';
import ITextField from 'ui-component/form/TextField';

const PrinterIdTextBox = () => {
  const printerId = useSelector((state) => state.printer.printerId);
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <Box sx={{ marginLeft: '1em' }}>
      <ITextField
        field="printerId"
        parent="print"
        placeholder={intl.formatMessage({ id: 'print.printerId' })}
        hideLabel
        preventDefault
        value={printerId}
        afterOnChange={(e) => dispatch(setPrinterId(e.target.value))}
        InputProps={{
          startAdornment: <PrintIcon />,
        }}
      />
    </Box>
  );
};

export default PrinterIdTextBox;
