import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';

const Organisers = Loadable(lazy(() => import('views/admin/pages/organisers')));
const Users = Loadable(lazy(() => import('views/admin/pages/users')));
const UserProfile = Loadable(lazy(() => import('views/register/pages/user')));

const AdminRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/organisers',
      element: <Organisers />,
    },
    {
      path: '/users',
      element: <Users />,
    },
    {
      path: '/user/:userId',
      element: <UserProfile />,
    },
  ],
};

export default AdminRoutes;
