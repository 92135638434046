import { IconFileSpark } from '@tabler/icons-react';
import IFormattedMessage from 'ui-component/basic/FormattedMessage';

const icons = {
  IconFileSpark,
};
const organisersPage = {
  id: 'organisers-page',
  title: <IFormattedMessage id="organisers-page" />,
  icon: icons.IconFileSpark,
  type: 'group',
  url: '/organisers-page',
};

export default organisersPage;
