import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import IFormattedMessage from 'ui-component/basic/FormattedMessage';
import { useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import { ThemeMode } from 'config';
import { useGetOrganiser } from 'api/organisers';
import { IconSearch, IconCaretDown } from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clickActiveEventFunc,
  triggerAddedEventFunc,
} from 'store/slices/event/event';
import { getBucketPath } from 'utils/getLogoUrl';
import { DASHBOARD_PATH } from 'config';
import { Roles } from 'utils/roles';
import useAuth from 'hooks/useAuth';
import PrinterIdTextBox from 'ui-component/PrinterId';

const EventListHeader = () => {
  const intl = useIntl();
  const { id, eventId } = useParams();
  const activeOrganiserId =
    useSelector((state) => state.organiser.activeOrganiser?.id) ?? id;

  const { data, dataLoading } = useGetOrganiser(activeOrganiserId);
  const { activeEvent } = useSelector((state) => state.event);
  const { user } = useAuth();
  const theme = useTheme();
  const { mode, borderRadius } = useConfig();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  // searchTerm'e göre filtrelenen items dizisi
  const filteredItems = data?.events.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const anchorRef = useRef(null);

  const handleListItemClick = (item) => {
    dispatch(() => {
      clickActiveEventFunc(item);
      navigate(`/organiser/${activeOrganiserId}/event/${item.id}`);
    });
  };

  useEffect(() => {
    if (!dataLoading) {
      dispatch(triggerAddedEventFunc({ data, eventId }));
      // Not checking {activeEvent} here because in between states it can be undefined and it would navigate immediately
      if (
        data &&
        !data?.events.find((item) => item.id === eventId) &&
        user?.role !== Roles.Admin // admin users will disregard the eventId parameter for this check
      ) {
        navigate(DASHBOARD_PATH);
      }
    }
  }, [dataLoading, activeEvent, data, dispatch, eventId, navigate]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          ml: 2,
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
          bgcolor: mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: 'primary.main',
            bgcolor: `${theme.palette.primary.main} !important`,
            color: 'primary.light',
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={getBucketPath(activeEvent?.logo, 500, 500)}
            alt="user-images"
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer',
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <>
            <Box display="flex" alignItems="center">
              <Box minWidth={'10em'}>
                <Typography variant="h5">{activeEvent?.name}</Typography>
              </Box>
              <IconCaretDown stroke={1.5} size="28px" />
            </Box>
          </>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        aria-label="user-account"
      />

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <OutlinedInput
                        sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                        id="input-search-profile"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder={intl.formatMessage({
                          id: 'actions.search',
                        })}
                        startAdornment={
                          <InputAdornment position="start">
                            <IconSearch stroke={1.5} size="16px" />
                          </InputAdornment>
                        }
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                      />
                      <Divider />
                    </Box>
                    <PerfectScrollbar
                      style={{
                        height: '100%',
                        maxHeight: 'calc(100vh - 250px)',
                        overflowX: 'hidden',
                      }}
                    >
                      <Box sx={{ p: 2, pt: 0 }}>
                        <Divider />
                        <List
                          component="nav"
                          sx={{
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 300,
                            borderRadius: `${borderRadius}px`,
                            '& .MuiListItemButton-root': { mt: 0.5 },
                          }}
                        >
                          {filteredItems?.length === 0 ? (
                            <>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                textAlign={'center'}
                                pt={2}
                              >
                                <IFormattedMessage id="event-search-not-found" />
                              </Typography>
                            </>
                          ) : (
                            filteredItems?.map((item, key) => (
                              <ListItemButton
                                key={key}
                                sx={{ borderRadius: `${borderRadius}px` }}
                                selected={selectedIndex === 0}
                                onClick={() => handleListItemClick(item)}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    src={getBucketPath(item.logo, 500, 500)}
                                    alt={item.name}
                                  />
                                </ListItemAvatar>

                                <ListItemText
                                  key={key}
                                  primary={
                                    <Typography component="h2" fontWeight={600}>
                                      {item.name}
                                    </Typography>
                                  }
                                />
                              </ListItemButton>
                            ))
                          )}
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
      <PrinterIdTextBox />
    </>
  );
};

export default EventListHeader;
