import { AuthorityGroup } from './authorityGroup';
import { Roles } from './roles';

export default function getLocalUserAuthority(
  user,
  organiserId,
  ownerId,
  eventId,
) {
  if (!user || !ownerId) return null;
  if (user?.role === Roles.Admin) return Roles.Admin;
  if (user.id === ownerId) return 'owner';

  const auth = user.authorities.find((a) =>
    Boolean(eventId) && a.authorityGroup !== AuthorityGroup.CoOwner
      ? a.organiser.id === organiserId && a.events.getIds().includes(eventId)
      : a.organiser.id === organiserId,
  );

  return auth?.authorityGroup;
}
