import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { PROFILE_PATH } from 'config';

const AuthGuard = ({ children, redirToProfile = false }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn || !Boolean(user)) {
      navigate(redirToProfile ? '/participant/login' : '/login', {
        replace: true,
        state: {
          redirectPath: location?.pathname,
        },
      });
    } else if (
      isLoggedIn &&
      user &&
      !user.affiliated &&
      !location?.pathname.includes(PROFILE_PATH) &&
      !location?.pathname.includes('ticket-management')
    ) {
      navigate(`/participant/profile/${user.id}`, {
        replace: true,
        state: {
          redirectPath: location?.pathname,
        },
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;
