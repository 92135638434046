import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const EventCoupons = Loadable(lazy(() => import('views/event-coupons')));
const RegisterForm = Loadable(lazy(() => import('views/register-form')));
const OrganisersPage = Loadable(lazy(() => import('views/organisers-page')));
const OrdersPage = Loadable(lazy(() => import('views/orders-page')));
const OrganiserDetailsPage = Loadable(
  lazy(() => import('views/organiser-details-page')),
);
const EventsPage = Loadable(lazy(() => import('views/event/Events')));
const EventDetails = Loadable(lazy(() => import('views/event-details-page')));
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const ParticipantSearch = Loadable(
  lazy(() => import('views/participant/Search')),
);
const VIPSearch = Loadable(
  lazy(() => import('views/participant/VIPSearch')),
);
const ParticipantList = Loadable(lazy(() => import('views/participant/List')));
const Sales = Loadable(lazy(() => import('views/sales/Sales')));
const Tags = Loadable(lazy(() => import('views/tags/Tags')));
const Program = Loadable(lazy(() => import('views/program')));
const Sponsors = Loadable(lazy(() => import('views/sponsors')));
const Faq = Loadable(lazy(() => import('views/faq')));
const Speakers = Loadable(lazy(() => import('views/speakers')));
const ControlPoints = Loadable(lazy(() => import('views/control-points')));
const TestControlPoints = Loadable(
  lazy(() => import('views/control-points/TestControlPoints')),
);
const BadgeDesigns = Loadable(lazy(() => import('views/badge-designs')));
const Promotion = Loadable(lazy(() => import('views/promotion')));
const Notifications = Loadable(lazy(() => import('views/notification')));

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: <DashboardPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardPage />,
    },
    {
      path: '/organisers-page',
      element: <OrganisersPage />,
    },
    {
      path: '/orders-page',
      element: <OrdersPage />,
    },
    {
      path: '/organiser/:id',
      element: <OrganiserDetailsPage />,
    },
    {
      path: '/events',
      element: <EventsPage />,
    },
    {
      path: '/organiser/:id/event/:eventId',
      element: <EventDetails />,
    },
    {
      path: '/organiser/:id/event/:eventId/info',
      element: <EventDetails />,
    },
    {
      path: '/organiser/:id/event/:eventId/search',
      element: <ParticipantSearch />,
    },
    {
      path: '/organiser/:id/event/:eventId/vip-search',
      element: <VIPSearch />,
    },
    {
      path: '/organiser/:id/event/:eventId/participants',
      element: <ParticipantList />,
    },
    {
      path: '/organiser/:id/event/:eventId/sales',
      element: <Sales />,
    },
    {
      path: '/organiser/:id/event/:eventId/tags',
      element: <Tags />,
    },
    {
      path: '/organiser/:id/event/:eventId/registration-forms',
      element: <RegisterForm />,
    },
    {
      path: '/organiser/:id/event/:eventId/coupons',
      element: <EventCoupons />,
    },
    {
      path: '/organiser/:id/event/:eventId/speakers',
      element: <Speakers />,
    },
    {
      path: '/organiser/:id/event/:eventId/program',
      element: <Program />,
    },
    {
      path: '/organiser/:id/event/:eventId/sponsors',
      element: <Sponsors />,
    },
    {
      path: '/organiser/:id/event/:eventId/sss',
      element: <Faq />,
    },
    {
      path: '/organiser/:id/event/:eventId/control-points',
      element: <ControlPoints />,
    },
    {
      path: '/organiser/:id/event/:eventId/test-control-points',
      element: <TestControlPoints />,
    },
    {
      path: '/organiser/:id/event/:eventId/badge-designs',
      element: <BadgeDesigns />,
    },
    {
      path: '/organiser/:id/event/:eventId/promotion',
      element: <Promotion />,
    },
    {
      path: '/organiser/:id/event/:eventId/notification',
      element: <Notifications />,
    },
  ],
};

export default MainRoutes;
