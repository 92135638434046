import axios from 'axios';
import snackbar from './snackbar';
import intl from './intl';

const axiosServices = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL || 'http://127.0.0.1:3000/',
});

const axiosServicesNoIntercept = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL || 'http://127.0.0.1:3000/',
});

axiosServices.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosServices.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && !window.location.href.includes('/login')) {
      // Access token expired
      if (error.response.status === 401) {
        try {
          const rs = await axiosServicesNoIntercept.post('/api/auth/refresh', {
            accessToken: localStorage.getItem('accessToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          });

          const data = rs.data;
          const { accessToken, refreshToken } = data;

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
        } catch (_error) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          window.location.href = `${window.location.origin}/login?message=API.session_time_out`;
        }
      } else {
        const { response, config } = error;
        const { url } = config;
        const { data, status } = response;
        const { message, params } = data;
        const toastVariant =
          !params || (params && !Boolean(params?.['snackVariant']))
            ? 'warning'
            : params?.['snackVariant'];

        if (status !== 401 && url !== '/api/auth/refresh') {
          if (status === 404 && url.includes('api/organiser')) {
            localStorage.removeItem('activeOrganiser');
          } else if (status === 404 && url.includes('auth/me')) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location.reload();
          } else if (message && status !== 429) {
            const mess_ = status === 404 ? 'not_found' : `${message}`;
            if (!params || (params && !Boolean(params?.['suppressSnack'])))
              snackbar.toast(
                intl().formatMessage({ id: `API.${mess_}` }, params),
                toastVariant,
                30000,
              );
          } else if (status !== 429) {
            if (!params || (params && !Boolean(params?.['suppressSnack'])))
              snackbar.toast(
                intl().formatMessage({ id: 'API.error' }),
                toastVariant,
              );
          }
        }
      }
    }
    return Promise.reject(
      (error.response && error.response.data) || 'Wrong Services',
    );
  },
);

export default axiosServices;

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.get(url, { ...config });

  return res.data;
};
