import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from 'App';
import { store } from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';
import 'assets/scss/style.scss';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'utils/snackbar';
import SnackbarCloseButton from 'ui-component/SnackbarCloseButton';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PostHogProvider } from 'posthog-js/react';

Array.prototype.removeDuplicates = function () {
  return this.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) =>
        typeof t === 'string' && typeof value === 'string'
          ? t === value
          : t.id === value.id,
      ),
  );
};

Array.prototype.sortBy = function sb(f) {
  for (var i = this.length; i; ) {
    var o = this[--i];
    this[i] = [].concat(f.call(o, o, i), o);
  }
  this.sort(function (a, b) {
    for (var i = 0, len = a.length; i < len; ++i) {
      if (a[i] != b[i]) return a[i] < b[i] ? -1 : 1;
    }
    return 0;
  });
  for (var i = this.length; i; ) {
    this[--i] = this[i][this[i].length - 1];
  }
  return this;
};

Array.prototype.getIds = function () {
  return this.map((value) => value.id);
};

const root = createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 120000, // 2 Minutes
    },
  },
});

// https://github.com/vitejs/vite/issues/11804
window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
  window.location.reload();
});

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />{' '}
    <PostHogProvider
      apiKey={import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
        person_profiles: 'always',
      }}
    >
      <Provider store={store}>
        <ConfigProvider>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
          >
            <SnackbarUtilsConfigurator />
            <App />
          </SnackbarProvider>
        </ConfigProvider>
      </Provider>
    </PostHogProvider>
  </QueryClientProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
