import { FormHelperText } from '@mui/material';
import { FormikContext } from 'formik';
import { get } from 'lodash-es';
import { useContext } from 'react';

export default function ErrorHelper({
  field,
  error = null,
  formik = null,
  isTl = false,
  ignoreTouched = false,
}) {
  const { errors, touched } = formik ??
    useContext(FormikContext) ?? {
      errors: {},
      touched: {},
    };

  const errorMessages = isTl
    ? (
        Object.values(errors?.translations ?? {})?.map(
          (err) => err?.[field]?.message ?? '',
        ) ?? []
      )
        .removeDuplicates()
        .filter(Boolean)
        .join(', ')
    : get(errors, field);

  return (
    <>
      {error ? (
        <FormHelperText error id={`helper-text-${field}`}>
          {error}
        </FormHelperText>
      ) : (
        <>
          {(get(touched, field) || ignoreTouched) && errorMessages && (
            <FormHelperText error id={`helper-text-${field}`}>
              {errorMessages}
            </FormHelperText>
          )}
        </>
      )}
    </>
  );
}
