import { useParams } from 'react-router-dom';
import getLocalUserAuthority from 'utils/getLocalUserAuthority';
import useAuth from './useAuth';
import { useGetOwnerId } from 'api/organisers';
import { Roles } from 'utils/roles';
import { useSelector } from 'store';

const useUserAuthority = () => {
  const {
    id, // organiserId
  } = useParams();
  const { user } = useAuth();
  const activeOrganiserId = useSelector(
    (state) => state.organiser.activeOrganiser?.id,
  );
  const { data: ownerId } = useGetOwnerId(id ?? activeOrganiserId);

  const authority = getLocalUserAuthority(user, id, ownerId);

  return user?.role === Roles.Admin ? 'admin' : authority;
};

export default useUserAuthority;
