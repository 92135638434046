import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import { Roles } from 'utils/roles';
import { ORGANISERS_PATH } from 'config';
import { PROFILE_PATH } from 'config';
import { jwtDecode } from 'jwt-decode';

const GuestGuard = ({ children, redirToProfile = false }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { redirectPath } = location?.state ?? {};
  const [searchParams] = useSearchParams();
  const verifyToken = searchParams.get('verifyToken');
  const invitationId = searchParams.get('invitation');

  useEffect(() => {
    if (isLoggedIn) {
      if (verifyToken) {
        const { email, participationId } = jwtDecode(verifyToken) ?? {};
        if (!email && !participationId) {
          navigate(
            redirToProfile
              ? `${PROFILE_PATH}/${user?.id}`
              : user?.role === Roles.Admin
                ? ORGANISERS_PATH
                : DASHBOARD_PATH,
            {
              replace: true,
            },
          );
        }
      } else if (!invitationId) {
        if (redirectPath) {
          navigate(redirectPath ?? '/', { replace: true });
        } else {
          navigate(
            redirToProfile
              ? `${PROFILE_PATH}/${user?.id}`
              : user?.role === Roles.Admin
                ? ORGANISERS_PATH
                : DASHBOARD_PATH,
            {
              replace: true,
            },
          );
        }
      }
    }
  }, [verifyToken, isLoggedIn, navigate, location.pathname]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
