import { lazy } from 'react';
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

const AuthLogin = Loadable(
  lazy(() => import('views/pages/authentication/Login')),
);
const AuthRegister = Loadable(
  lazy(() => import('views/pages/authentication/Register')),
);
const AuthForgotPassword = Loadable(
  lazy(() => import('views/pages/authentication/ForgotPassword')),
);
const AuthCheckMail = Loadable(
  lazy(() => import('views/pages/authentication/CheckMail')),
);
const AuthResetPassword = Loadable(
  lazy(() => import('views/pages/authentication/ResetPassword')),
);

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/',
      element: <AuthLogin isParticipant={false} />,
    },
    {
      path: '/login',
      element: <AuthLogin isParticipant={false} />,
    },
    {
      path: '/register',
      element: <AuthRegister />,
    },
    {
      path: '/forgot',
      element: <AuthForgotPassword />,
    },
    {
      path: '/check-mail',
      element: <AuthCheckMail />,
    },
    {
      path: '/reset-password',
      element: <AuthResetPassword />,
    },
  ],
};

export default LoginRoutes;
