import { createBrowserRouter } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import RegisterRoutes from './RegisterRoutes';
import ParticipantRoutes from './ParticipantRoutes';
import Error from './Error';
import AdminRoutes from './AdminRoutes';
import LegalRoutes from './Legal';
import ParticipantRoutesMinimal from './ParticipantRoutesMinimal';

const router = createBrowserRouter(
  [
    LoginRoutes,
    AuthenticationRoutes,
    AdminRoutes,
    MainRoutes,
    RegisterRoutes,
    ParticipantRoutes,
    ParticipantRoutesMinimal,
    Error,
    LegalRoutes
  ],
  { basename: import.meta.env.VITE_APP_BASE_NAME },
);
export default router;
