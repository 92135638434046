import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MainUserLayout from 'layout/MainUserLayout';
import { Container } from '@mui/material';
import GuestGuard from 'utils/route-guard/GuestGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AuthLogin = Loadable(
  lazy(() => import('views/pages/authentication/Login')),
);
const UserProfile = Loadable(lazy(() => import('views/register/pages/user')));

const ParticipantRoutes = {
  path: '/',
  element: <MainUserLayout />,
  children: [
    {
      path: '/participant/login',
      element: (
        <GuestGuard redirToProfile>
          <AuthLogin />
        </GuestGuard>
      ),
    },
    {
      path: '/participant/profile/:userId',
      element: (
        <AuthGuard redirToProfile>
          <Container
            sx={{
              mt: 2,
            }}
          >
            <UserProfile />
          </Container>
        </AuthGuard>
      ),
    },
  ],
};

export default ParticipantRoutes;
