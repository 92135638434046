import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isEvent: false,
  activeEvent: {},
  lastVisitedFormId: null,
  selectedLanguage: import.meta.env.VITE_DEFAULT_LANGUAGE,
};

export const EventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setLastVisitedFormId: (state, action) => {
      state.lastVisitedFormId = action.payload;
    },
    eventActiveFunc: (state, action) => {
      state.isEvent = action.payload;
    },
    clickActiveEventFunc: (state, action) => {
      state.activeEvent = action.payload;
    },
    triggerAddedEventFunc: (state, action) => {
      const events = action.payload.data?.events;
      if (events) {
        state.activeEvent = events.find(
          (item) => item.id === action.payload.eventId,
        );
      }
    },
    changeSelectLanguageFunc: (state, action) => {
      if (action.payload.length > 0) {
        state.selectedLanguage = action.payload[0].code;
      } else {
        state.selectedLanguage = import.meta.env.VITE_DEFAULT_LANGUAGE;
      }
    },
    changeButtonLanguageFunc: (state, action) => {
      state.selectedLanguage = action.payload;
    },
  },
});

export const {
  eventActiveFunc,
  setLastVisitedFormId,
  clickActiveEventFunc,
  triggerAddedEventFunc,
  changeSelectLanguageFunc,
  changeButtonLanguageFunc,
} = EventSlice.actions;
export default EventSlice.reducer;
